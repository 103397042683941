import { useEffect } from "react";
import "./App.css";
import Map from "./pages/map";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import Monitoramento from "./pages/monitoramento";
// import { useSnackbar } from "notistack";

function App() {
	// const snackbar = useSnackbar();
  // useEffect(() => {
  //   snackbar.enqueueSnackbar(
  //     // "Está disponível na REDEMET até 03 de maio de 2024 a pesquisa de satisfação sobre a prestação dos serviços meteorológicos das EMS integrantes do escopo do Sistema de Gestão da Qualidade Multisite (SGQM).",
	// 		<a
	// 			href="https://forms.gle/Y8JL4R6PshGu1kFLA"
	// 			target="_blank"
	// 			rel="noreferrer"
	// 			style={{ textDecoration: 'none', color: '#fff' }}
	// 		>
	// 			Pesquisa sobre a carta SIGWX.
	// 		</a>,
  //     {
  //       variant: "warning",
  //       style: { whiteSpace: "pre-line" },
  //       autoHideDuration: 8000,
  //       anchorOrigin: {
  //         vertical: "bottom",
  //         horizontal: "right",
  //       },
  //     }
  //   );
  // }, [snackbar]);

  const ga4Config = {
    measurementId: "G-36SV7PVJYY",
  };

  ReactGA.initialize(ga4Config.measurementId);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Map />
        </Route>
        <Route path="/monitoramento">
          <Monitoramento />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
